import React, {Fragment, useContext, useEffect, useState} from "react";
import Card from "../../components/Card";
import {useHistory} from "react-router-dom";
import {LoaderContext} from "../../context/LoaderContext";
import axios from "axios";
import Pager from "../../components/Pager";
import {
    convertiDateInDataPerDb,
    getDataPerDb,
    getUtenteFromToken,
    getUtenteLoggato,
    STATO_CERTIFICATO_APPROVATO_DA_COMMISSARIO,
    STATO_CERTIFICATO_APPROVATO_DA_STAFF,
    STATO_CERTIFICATO_BOZZA, STATO_CERTIFICATO_BOZZA_PRESA_IN_CARICO,
    STATO_CERTIFICATO_DA_GESTIRE,
    STATO_CERTIFICATO_FIRMATO_DA_PRESIDENTE,
    STATO_CERTIFICATO_IN_APPROVAZIONE,
    STATO_CERTIFICATO_RIFIUTATO,
    STATO_CERTIFICATO_RIFIUTATO_DA_COMMISSARIO,
    STATO_CERTIFICATO_RIGETTATO_DA_PRESIDENTE,
    STATO_CERTIFICATO_SOCIO_APPROVATO,
    STATO_CERTIFICATO_SOCIO_IN_ATTESA,
    STATO_CERTIFICATO_SOCIO_RIFIUTATO,
    STATO_IN_VERIFICA_COMMISSARIO
} from "../../utility/common";
import {RuoliContext} from "../../context/RuoliContext";
import {AuthContext} from "../../context/AuthContext";
import TabellaCertificati from "./TabellaCertificati";
import ModalLegendaStatoCert from "./ModalLegendaStatoCert";
import {Form, Formik} from "formik";
import Translate from "../../components/Translate";
import NsAccordion from "../../components/accordion/NsAccordion";
import {FiltersContext} from "../../context/FiltersContext";
import NsDatePicker from "../../components/NsDatePicker";

function ListCertificati() {

    const {isValido} = useContext(AuthContext);
    const {isRuoloSocio, isRuoloAdmin, isRuoloSegreteria, isRuoloClub, isRuoloAgenzia} = useContext(RuoliContext);
    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {filtroCertificati, initFiltroCertificati, updateFiltroCertificati} = useContext(FiltersContext);
    const history = useHistory();

    const [loadingData, setLoadingData] = useState(true);
    const [loadingVeicoli, setLoadingVeicoli] = useState(true);
    const [pagina, setPagina] = useState(1);
    const [totPagine, setTotPagine] = useState();
    const [certificati, setCertificati] = useState([]);
    const [tipiCertificato, setTipiCertificato] = useState([]);
    const [totCertificati, setTotCertificati] = useState(0);
    const [veicoli, setVeicoli] = useState([]);
    const [showLegendaStato, setShowLegendaStato] = useState(false);
    const [stato, setStato] = useState(!isRuoloSocio() && !isRuoloClub() && !isRuoloAgenzia() ? STATO_CERTIFICATO_DA_GESTIRE : null);

    let user = getUtenteLoggato();

    function getFiltriCertificati() {
        let f = '?pag=' + pagina;
        if ((isRuoloAdmin() || isRuoloSegreteria() || isRuoloClub() || isRuoloAgenzia())) {
            f += '&certDaGestire=' + (stato && stato == STATO_CERTIFICATO_DA_GESTIRE ? '1' : '0');

            if (stato && stato !== '') {
                f += '&stato=' + stato;
            }
            if (filtroCertificati.nominativo && filtroCertificati.nominativo !== '') {
                f += '&nominativo=' + filtroCertificati.nominativo;
            }
            if (filtroCertificati.targa && filtroCertificati.targa !== '') {
                f += '&targa=' + filtroCertificati.targa;
            }
            if (filtroCertificati.marca && filtroCertificati.marca !== '') {
                f += '&marca=' + filtroCertificati.marca;
            }
            if (filtroCertificati.tipo && filtroCertificati.tipo !== '') {
                f += '&tipo=' + filtroCertificati.tipo;
            }
            if (filtroCertificati.id_tipo_certificato && filtroCertificati.id_tipo_certificato !== '') {
                f += '&id_tipo_certificato=' + filtroCertificati.id_tipo_certificato;
            }
            if (filtroCertificati.data_pagamento_da && filtroCertificati.data_pagamento_da !== '') {
                f += '&data_pagamento_da=' + convertiDateInDataPerDb(filtroCertificati.data_pagamento_da);
            }
            if (filtroCertificati.data_pagamento_a && filtroCertificati.data_pagamento_a !== '') {
                f += '&data_pagamento_a=' + convertiDateInDataPerDb(filtroCertificati.data_pagamento_a);
            }
            if (filtroCertificati.data_registrazione_da && filtroCertificati.data_registrazione_da !== '') {
                f += '&data_registrazione_da=' + convertiDateInDataPerDb(filtroCertificati.data_registrazione_da);
            }
            if (filtroCertificati.data_registrazione_a && filtroCertificati.data_registrazione_a !== '') {
                f += '&data_registrazione_a=' + convertiDateInDataPerDb(filtroCertificati.data_registrazione_a);
            }
        }
        return f;
    }

    function getCertificati() {
        let f = getFiltriCertificati();

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + f).then((response) => {
            setCertificati(response.data.data);
            setTotPagine(response.data.pages)
            setTotCertificati(response.data.tot)
            setLoadingData(false);
            endChiamata()
        });
    }

    function getTipiCertificato() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/tipo-certificato/").then((response) => {
            setTipiCertificato(response.data.data);
            endChiamata()
        });
    }

    function getVeicoli() {
        if (isRuoloSocio() || isRuoloClub() || isRuoloAgenzia()) {
            let token = localStorage.getItem('rfi-login');
            if (token) {
                let user = getUtenteFromToken(token);
                if (user) {
                    startChiamata()
                    axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/?pag=-1&id_utente=" + user.id_utente).then((response) => {
                        setVeicoli(response.data.data);
                        setLoadingVeicoli(false);
                        endChiamata()
                    });
                }
            }
        }
    }

    useEffect(() => {
        getCertificati();
        getTipiCertificato();
        if (isRuoloSocio() || isRuoloClub() || isRuoloAgenzia()) {
            getVeicoli();
        }
    }, [pagina, stato]);

    const cambioPagina = (nuovaPagina) => {
        setPagina(nuovaPagina)
    }

    function isSocioSenzaVeicoli() {
        return isRuoloSocio() && veicoli.length === 0 && !loadingVeicoli;
    }

    const deleteCertificato = (certificato) => {
        let f = getFiltriCertificati();

        startChiamata()
        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/certificato/" + certificato.id_certificato + f).then((response) => {
            setCertificati(response.data.data);
            setTotPagine(response.pages)
            setTotCertificati(response.tot)
            setPagina(1)
            endChiamata()
        });
    }

    return (
        <Card
            icon={'fas fa-file-signature'}
            title={'Certificati'}
            getBottoniDx={() =>
                <Fragment>
                    {
                        (isRuoloSocio() || isRuoloClub() || isRuoloAgenzia()) && isValido() ?
                            <button onClick={() => history.push("/upd-certificato/" + user.id_utente)}
                                    className="btn btn-card"
                                    data-toggle="tooltip" title="Aggiungi"
                                    disabled={isSocioSenzaVeicoli()}
                            >
                                <i className="fas fa-plus-circle"/> Aggiungi
                            </button>
                            : ''
                    }
                </Fragment>
            }>
            <ModalLegendaStatoCert
                show={showLegendaStato}
                handleClose={() => {
                    setShowLegendaStato(false);
                }}
            />
            <div className={"row mb-4"}>
                <div className={"col-md-6"}>
                    <p>
                        Legenda:&nbsp;&nbsp;
                        <i className="fas fa-info-circle rfi-cursor--pointer"
                           onClick={() => {
                               setShowLegendaStato(true)
                           }}/>

                    </p>
                </div>
                <div className={"col-md-12"}>
                    {
                        (isRuoloAdmin() || isRuoloSegreteria() || isRuoloClub() || isRuoloAgenzia()) &&
                        <Fragment>
                            <Formik
                                onSubmit={getCertificati}
                                initialValues={{}}>
                                <Form>
                                    <div className={'row pb-2'}>
                                        <div className={'col-md-2'}>
                                            <input
                                                type={"text"}
                                                id={"nominativo"}
                                                className="form-control"
                                                placeholder={"Nome/Cognome socio"}
                                                value={filtroCertificati.nominativo}
                                                onChange={(ev) => {
                                                    updateFiltroCertificati("nominativo", ev.target.value);
                                                }}/>
                                        </div>
                                        <div className={'col-md-4'}>
                                            {
                                                (isRuoloClub() || isRuoloAgenzia()) ?
                                                    <select
                                                        name={"gestione"}
                                                        className={"form-control form-select"}
                                                        value={stato}
                                                        onChange={e => {
                                                            setStato(e.target.value);
                                                        }}
                                                    >
                                                        <option value={""}>Tutti i certificati</option>
                                                        <option value={STATO_CERTIFICATO_BOZZA}>Bozza</option>
                                                        <option
                                                            value={STATO_CERTIFICATO_SOCIO_IN_ATTESA}>In Attesa
                                                        </option>
                                                        <option
                                                            value={STATO_CERTIFICATO_RIFIUTATO}>Rifiutato
                                                        </option>
                                                        <option
                                                            value={STATO_CERTIFICATO_FIRMATO_DA_PRESIDENTE}>Approvato
                                                        </option>
                                                    </select>
                                                    :
                                                    <select
                                                        name={"gestione"}
                                                        className={"form-control form-select"}
                                                        value={stato}
                                                        onChange={e => {
                                                            setStato(e.target.value);
                                                        }}
                                                    >
                                                        <option value={""}>Tutti i certificati</option>
                                                        <option value={STATO_CERTIFICATO_DA_GESTIRE}>Da gestire</option>
                                                        <option value={STATO_CERTIFICATO_BOZZA}>Bozza</option>
                                                        <option value={STATO_CERTIFICATO_BOZZA_PRESA_IN_CARICO}>Bozza presa in carico</option>
                                                        <option value={STATO_CERTIFICATO_IN_APPROVAZIONE}>Richiesta di
                                                            approvazione effettuata
                                                        </option>
                                                        <option value={STATO_IN_VERIFICA_COMMISSARIO}>In verifica da
                                                            parte di un
                                                            Commissario
                                                        </option>
                                                        <option
                                                            value={STATO_CERTIFICATO_APPROVATO_DA_COMMISSARIO}>Approvato
                                                            dal
                                                            Commissario
                                                        </option>
                                                        <option
                                                            value={STATO_CERTIFICATO_RIFIUTATO_DA_COMMISSARIO}>Rifiutato
                                                            dal
                                                            Commissario
                                                        </option>
                                                        <option value={STATO_CERTIFICATO_APPROVATO_DA_STAFF}>Approvato
                                                        </option>
                                                        <option value={STATO_CERTIFICATO_RIFIUTATO}>Rifiutato</option>
                                                        <option value={STATO_CERTIFICATO_FIRMATO_DA_PRESIDENTE}>Firmato
                                                            dal
                                                            Presidente
                                                        </option>
                                                        <option
                                                            value={STATO_CERTIFICATO_RIGETTATO_DA_PRESIDENTE}>Rifiutato
                                                            dal
                                                            Presidente
                                                        </option>
                                                    </select>
                                            }
                                        </div>
                                        <div className={'col-md-4'}>
                                            <button type={"submit"} className={"btn btn-dark"}>
                                                <i className="fas fa-search"></i> <Translate>Cerca</Translate>
                                            </button>
                                            <button type="button" className={"btn btn-dark"} onClick={() => {
                                                initFiltroCertificati();
                                            }}>
                                                <i className="fas fa-trash"></i> <Translate>Azzera filtri</Translate>
                                            </button>
                                        </div>
                                    </div>
                                    <NsAccordion
                                        titolo={'Filtri'}
                                        label={'fas fa-filter'}
                                        defaultKey={""}
                                    >
                                        <div className={'row'}>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"targa"}>Targa:</label>
                                                <input
                                                    type={"text"}
                                                    id={"targa"}
                                                    className="form-control"
                                                    value={filtroCertificati.targa}
                                                    onChange={(ev) => {
                                                        updateFiltroCertificati("targa", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"marca"}>Marca:</label>
                                                <input
                                                    type={"text"}
                                                    id={"marca"}
                                                    className="form-control"
                                                    value={filtroCertificati.marca}
                                                    onChange={(ev) => {
                                                        updateFiltroCertificati("marca", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"tipo"}>Tipo veicolo:</label>
                                                <input
                                                    type={"text"}
                                                    id={"tipo"}
                                                    className="form-control"
                                                    value={filtroCertificati.tipo}
                                                    onChange={(ev) => {
                                                        updateFiltroCertificati("tipo", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-6'}>
                                                <label htmlFor={"id_tipo_certificato"}>Tipo Certificato:</label>
                                                <select
                                                    id={"tipo"}
                                                    className="form-control form-select"
                                                    value={filtroCertificati.id_tipo_certificato}
                                                    onChange={(ev) => {
                                                        updateFiltroCertificati("id_tipo_certificato", ev.target.value);
                                                    }}>
                                                    <option value={""}>Tutti i tipi</option>
                                                    {
                                                        tipiCertificato && tipiCertificato.map(tc =>
                                                            <option value={tc.id_tipo_certificato}
                                                                    key={tc.id_tipo_certificato}>{tc.nome}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"data_pagamento_da"}>Data pagamento dal:</label>
                                                <NsDatePicker
                                                    nome={"data_pagamento_da"}
                                                    dataSelezionata={filtroCertificati.data_pagamento_da}
                                                    maxDate={filtroCertificati.data_pagamento_da}
                                                    setSelectedDate={(date, event) => {
                                                        updateFiltroCertificati("data_pagamento_da", date);
                                                    }}
                                                />
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"data_pagamento_a"}>Data pagamento al:</label>
                                                <NsDatePicker
                                                    nome={"data_pagamento_a"}
                                                    dataSelezionata={filtroCertificati.data_pagamento_a}
                                                    minDate={filtroCertificati.data_pagamento_a}
                                                    setSelectedDate={(date, event) => {
                                                        updateFiltroCertificati("data_pagamento_a", date);
                                                    }}
                                                />
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"data_registrazione_da"}>Registrazione dal:</label>
                                                <NsDatePicker
                                                    nome={"data_registrazione_da"}
                                                    dataSelezionata={filtroCertificati.data_registrazione_da}
                                                    maxDate={filtroCertificati.data_registrazione_da}
                                                    setSelectedDate={(date, event) => {
                                                        updateFiltroCertificati("data_registrazione_da", date);
                                                    }}
                                                />
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"data_registrazione_a"}>Registrazione al:</label>
                                                <NsDatePicker
                                                    nome={"data_registrazione_a"}
                                                    dataSelezionata={filtroCertificati.data_registrazione_a}
                                                    minDate={filtroCertificati.data_registrazione_a}
                                                    setSelectedDate={(date, event) => {
                                                        updateFiltroCertificati("data_registrazione_a", date);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </NsAccordion>
                                </Form>
                            </Formik>
                        </Fragment>
                    }
                </div>
            </div>
            {
                loadingData ? (
                    <p>Caricamento in corso...</p>
                ) : (
                    <Fragment>
                        {
                            isSocioSenzaVeicoli() ?
                                <Fragment>
                                    <h3>Devi inserire almeno un veicolo per poter richiedere i documenti.</h3>
                                </Fragment>
                                : <TabellaCertificati
                                    certificati={certificati}
                                    totCertificati={totCertificati}
                                    deleteCertificato={deleteCertificato}
                                    postUpdate={getCertificati}
                                />
                        }
                    </Fragment>
                )}
            {
                totPagine ?
                    (
                        <Pager
                            pagina={pagina}
                            totale={totPagine}
                            handlerCambioPagina={cambioPagina}
                        >
                        </Pager>
                    ) : ''
            }
        </Card>
    );
}

export default ListCertificati;
