import React, {Fragment, useContext, useEffect, useState} from "react";
import CardContainer from "../../components/CardContainer";
import Card from "../../components/Card";
import {Link} from "react-router-dom";
import {LoaderContext} from "../../context/LoaderContext";
import axios from "axios";
import Pager from "../../components/Pager";
import NsAccordion from "../../components/accordion/NsAccordion";
import {
    convertiDateInDataPerDb,
    getUtenteFromToken,
    ORDINE_VEICOLI_ABARTH_ASC, ORDINE_VEICOLI_COGNOME_ASC, ORDINE_VEICOLI_MARCA_ASC,
    ORDINE_VEICOLI_RFI_ASC,
    ORDINE_VEICOLI_TIPO_SOCIO
} from "../../utility/common";
import Translate from "../../components/Translate";
import {RuoliContext} from "../../context/RuoliContext";
import NsDatePicker from "../../components/NsDatePicker";
import {AuthContext} from "../../context/AuthContext";
import TabellaVeicoli from "./TabellaVeicoli";
import {Form, Formik} from "formik";
import {FiltersContext} from "../../context/FiltersContext";

function ListVeicoli() {

    const [registro, setRegistro] = useState('');
    const [regione, setRegione] = useState('');
    const [tipoSocio, setTipoSocio] = useState('');
    const [daRatificare, setDaRatificare] = useState('');
    const [newsletter, setNewsletter] = useState('');
    const [club, setClub] = useState('');
    const [tipoCarrozzeria, setTipoCarrozzeria] = useState('');
    const [colore, setColore] = useState('');
    const [registroVeicoli, setRegistroVeicoli] = useState('');
    const [bollo, setBollo] = useState('');
    const [omologato, setOmologato] = useState('');

    const [loadingData, setLoadingData] = useState(true);
    const [veicoli, setVeicoli] = useState([]);
    const [totVeicoli, setTotVeicoli] = useState(0);
    const [regioni, setRegioni] = useState([]);
    const [tipiSoci, setTipiSoci] = useState([]);
    const [clubs, setClubs] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [totPagine, setTotPagine] = useState();
    const [tipiRegistro, setTipiRegistro] = useState([]);
    const [carrozzerie, setCarrozzerie] = useState([]);
    const [colori, setColori] = useState([]);
    const [registriVeicoli, setRegistriVeicoli] = useState([]);
    const [user, setUser] = useState();

    const {isValido} = useContext(AuthContext);
    const {startChiamata, endChiamata} = useContext(LoaderContext);
    const {filtroVeicoli, initFiltroVeicoli, updateFiltroVeicoli} = useContext(FiltersContext);
    const {isRuoloSocio, isRuoloClub, isRuoloAgenzia} = useContext(RuoliContext);

    function getFiltriVeicoli(paginaCorrente) {
        let f = '?pag=' + paginaCorrente;
        if (filtroVeicoli.nome && filtroVeicoli.nome !== '') {
            f += '&nome=' + filtroVeicoli.nome;
        }
        if (filtroVeicoli.cognome && filtroVeicoli.cognome !== '') {
            f += '&cognome=' + filtroVeicoli.cognome;
        }
        if (filtroVeicoli.filtroVeicolo && filtroVeicoli.filtroVeicolo !== '') {
            f += '&filtroVeicolo=' + filtroVeicoli.filtroVeicolo;
        }
        if (filtroVeicoli.marca && filtroVeicoli.marca !== '') {
            f += '&marca=' + filtroVeicoli.marca;
        }
        if (filtroVeicoli.tipo && filtroVeicoli.tipo !== '') {
            f += '&tipo=' + filtroVeicoli.tipo;
        }
        if (filtroVeicoli.registro && filtroVeicoli.registro !== '') {
            let reg = filtroVeicoli.registro;
            if (reg === '3' || reg === '5') {
                reg += ',15';
            }
            f += '&registro=' + reg;
        }
        if (filtroVeicoli.codiceFiscale && filtroVeicoli.codiceFiscale !== '') {
            f += '&codice_fiscale=' + filtroVeicoli.codiceFiscale;
        }
        if (filtroVeicoli.azienda && filtroVeicoli.azienda !== '') {
            f += '&azienda=' + filtroVeicoli.azienda;
        }
        if (filtroVeicoli.notePersona && filtroVeicoli.notePersona !== '') {
            f += '&note_prop=' + filtroVeicoli.notePersona;
        }
        if (filtroVeicoli.indirizzo && filtroVeicoli.indirizzo !== '') {
            f += '&indirizzo=' + filtroVeicoli.indirizzo;
        }
        if (filtroVeicoli.cap && filtroVeicoli.cap !== '') {
            f += '&cap=' + filtroVeicoli.cap;
        }
        if (filtroVeicoli.localita && filtroVeicoli.localita !== '') {
            f += '&localita=' + filtroVeicoli.localita;
        }
        if (filtroVeicoli.provincia && filtroVeicoli.provincia !== '') {
            f += '&provincia=' + filtroVeicoli.provincia;
        }
        if (filtroVeicoli.regione && filtroVeicoli.regione !== '') {
            f += '&regione=' + filtroVeicoli.regione;
        }
        if (filtroVeicoli.nazione && filtroVeicoli.nazione !== '') {
            f += '&nazione=' + filtroVeicoli.nazione;
        }
        if (filtroVeicoli.tipoSocio && filtroVeicoli.tipoSocio !== '') {
            f += '&tipo_socio=' + filtroVeicoli.tipoSocio;
        }
        if (filtroVeicoli.annoIscrizione && filtroVeicoli.annoIscrizione !== '') {
            f += '&anno_iscrizione=' + filtroVeicoli.annoIscrizione;
        }
        if (filtroVeicoli.filtroTessera && filtroVeicoli.filtroTessera !== '') {
            f += '&tessera=' + filtroVeicoli.filtroTessera;
        }
        if (filtroVeicoli.scadenza && filtroVeicoli.scadenza !== '') {
            f += '&scadenza=' + filtroVeicoli.scadenza;
        }
        if (filtroVeicoli.daRatificare && filtroVeicoli.daRatificare !== '') {
            f += '&da_ratificare=' + filtroVeicoli.daRatificare;
        }
        if (filtroVeicoli.newsletter && filtroVeicoli.newsletter !== '') {
            f += '&newsletter=' + filtroVeicoli.newsletter;
        }
        if (filtroVeicoli.delegazione && filtroVeicoli.delegazione !== '') {
            f += '&delegazione=' + filtroVeicoli.delegazione;
        }
        if (filtroVeicoli.tesseraAsi && filtroVeicoli.tesseraAsi !== '') {
            f += '&tesseraAsi=' + filtroVeicoli.tesseraAsi;
        }
        if (filtroVeicoli.anno && filtroVeicoli.anno !== '') {
            f += '&anno=' + filtroVeicoli.anno;
        }
        if (filtroVeicoli.club && filtroVeicoli.club !== '') {
            f += '&club=' + filtroVeicoli.club;
        }
        if (filtroVeicoli.targa && filtroVeicoli.targa !== '') {
            f += '&targa=' + filtroVeicoli.targa;
        }
        if (filtroVeicoli.carrozzeria && filtroVeicoli.carrozzeria !== '') {
            f += '&carrozzeria=' + filtroVeicoli.carrozzeria;
        }
        if (filtroVeicoli.marcaCarrozzeria && filtroVeicoli.marcaCarrozzeria !== '') {
            f += '&marca_carrozzeria=' + filtroVeicoli.marcaCarrozzeria;
        }
        if (filtroVeicoli.colore && filtroVeicoli.colore !== '') {
            f += '&colore=' + filtroVeicoli.colore;
        }
        if (filtroVeicoli.tipoMotore && filtroVeicoli.tipoMotore !== '') {
            f += '&tipo_motore=' + filtroVeicoli.tipoMotore;
        }
        if (filtroVeicoli.numeroMotore && filtroVeicoli.numeroMotore !== '') {
            f += '&numero_motore=' + filtroVeicoli.numeroMotore;
        }
        if (filtroVeicoli.numeroTelaio && filtroVeicoli.numeroTelaio !== '') {
            f += '&numero_telaio=' + filtroVeicoli.numeroTelaio;
        }
        if (filtroVeicoli.registroVeicolo && filtroVeicoli.registroVeicolo !== '') {
            f += '&registro_veicolo=' + filtroVeicoli.registroVeicolo;
        }
        if (filtroVeicoli.numeroRegistrazione && filtroVeicoli.numeroRegistrazione !== '') {
            f += '&numero_registrazione=' + filtroVeicoli.numeroRegistrazione;
        }
        if (filtroVeicoli.data_registrazione && filtroVeicoli.data_registrazione !== '') {
            f += '&data_registrazione=' + convertiDateInDataPerDb(filtroVeicoli.data_registrazione);
        }
        if (filtroVeicoli.bollo && filtroVeicoli.bollo !== '') {
            f += '&bollo_rfi=' + filtroVeicoli.bollo;
        }
        if (filtroVeicoli.omologato && filtroVeicoli.omologato !== '') {
            f += '&omologato=' + filtroVeicoli.omologato;
        }
        if (filtroVeicoli.noteVeicolo && filtroVeicoli.noteVeicolo !== '') {
            f += '&note_veicolo=' + filtroVeicoli.noteVeicolo;
        }
        if (filtroVeicoli.ordine && filtroVeicoli.ordine !== '') {
            f += '&ordine=' + filtroVeicoli.ordine;
        }
        if (filtroVeicoli.data_da && filtroVeicoli.data_da !== '') {
            f += '&data_da=' + convertiDateInDataPerDb(filtroVeicoli.data_da);
        }
        if (filtroVeicoli.data_a && filtroVeicoli.data_a !== '') {
            f += '&data_a=' + convertiDateInDataPerDb(filtroVeicoli.data_a);
        }
        return f;
    }

    function getVeicoli(paginaCorrente) {
        let f = getFiltriVeicoli(paginaCorrente);

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + f).then((response) => {
            setVeicoli(response.data.data);
            setTotPagine(response.data.pages);
            setTotVeicoli(response.data.tot);
            setLoadingData(false);
            endChiamata()
        });
    }

    function getTipiSoci() {
        if (filtroVeicoli.registro && filtroVeicoli.registro !== '') {
            startChiamata()
            axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipi-socio/?tipo=" + filtroVeicoli.registro).then((response) => {
                setTipiSoci(response.data.data)
                endChiamata()
            });
        }
    }

    function getClubs() {
        if (filtroVeicoli.registro && filtroVeicoli.registro !== '') {
            startChiamata()
            axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/club/?tipo=" + filtroVeicoli.registro).then((response) => {
                setClubs(response.data.data)
                endChiamata()
            });
        }
    }

    function getTipiRegistro() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/utente/tipi-registro/").then((response) => {
            setTipiRegistro(response.data);
            endChiamata()
        });
    }

    function getRegioni() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/indirizzo/regione/").then((response) => {
            setRegioni(response.data.data)
            endChiamata()
        });
    }

    function getCarrozzerie() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/carrozzeria/").then((response) => {
            setCarrozzerie(response.data.data)
            endChiamata()
        });
    }

    function getColori() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/colore/").then((response) => {
            setColori(response.data.data)
            endChiamata()
        });
    }

    function getRegistriVeicoli() {
        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/registro/").then((response) => {
            setRegistriVeicoli(response.data.data)
            endChiamata()
        });
    }

    useEffect(() => {
        getVeicoli(1);
        getTipiSoci();
        if (!isRuoloClub() && !isRuoloAgenzia()) {
            getClubs();
        }

        if (loadingData === true) {
            let token = localStorage.getItem('rfi-login');
            if (token) {
                let user = getUtenteFromToken(token);
                if (user) {
                    setUser(user);
                }
            }

            getTipiRegistro();
            getRegioni();
            getCarrozzerie();
            getColori();
            getRegistriVeicoli();
        }
    }, [registro, regione, tipoSocio, daRatificare, newsletter, club, tipoCarrozzeria, colore,
        registroVeicoli, bollo, omologato]);

    useEffect(() => {
        if (pagina && loadingData === false) {
            getVeicoli(pagina);
        }
    }, [pagina]);

    const deleteVeicolo = (veicolo) => {
        let f = getFiltriVeicoli(1);

        startChiamata();
        axios.delete(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/" + veicolo.id_veicolo + f).then((response) => {
            setVeicoli(response.data.data);
            setTotPagine(response.data.pages);
            setTotVeicoli(response.data.tot);
            setPagina(1)
            endChiamata()
        });
    }

    function getDownloadCsv() {
        let f = getFiltriVeicoli(pagina);

        startChiamata()
        axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/rfi/veicolo/csv/" + f).then((response) => {
            endChiamata()
            let resp = response.data.data;
            if (resp) {
                var a = document.createElement("a");
                a.href = "data:text/csv;base64," + resp;
                a.download = 'elenco-veicoli-' + convertiDateInDataPerDb(new Date()) + '.csv';
                a.click(); //Downloaded file
            }
        });
    }

    return (
        <CardContainer>
            <Card
                icon={'fas fa-car'}
                title={'Veicoli'}
                getBottoniDx={() =>
                    <Fragment>
                        {
                            isRuoloSocio() && user && isValido() ?
                                <Link to={"/upd-veicolo/" + user.id_utente}
                                      className="btn btn-card"
                                      data-toggle="tooltip" title="Aggiungi">
                                    <i className="fas fa-plus-circle"/> Aggiungi
                                </Link>
                                : ''
                        }
                    </Fragment>
                }>
                {
                    !isRuoloSocio() ?
                        <Fragment>
                            <Formik
                                onSubmit={() => {
                                    getVeicoli(1);
                                }}
                                initialValues={{}}>
                                <Form>
                                    <div className={'row pb-2'}>
                                        <div className={'col-md-1'}>
                                            <input
                                                type={"text"}
                                                id={"nome"}
                                                placeholder={"Nome"}
                                                className="form-control"
                                                value={filtroVeicoli.nome}
                                                onChange={(ev) => {
                                                    updateFiltroVeicoli("nome", ev.target.value);
                                                }}/>
                                        </div>
                                        <div className={'col-md-1'}>
                                            <input
                                                type={"text"}
                                                id={"cognome"}
                                                placeholder={"Cognome"}
                                                className="form-control"
                                                value={filtroVeicoli.cognome}
                                                onChange={(ev) => {
                                                    updateFiltroVeicoli("cognome", ev.target.value);
                                                }}/>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <input
                                                type={"text"}
                                                id={"marca"}
                                                placeholder={"Marca"}
                                                className="form-control"
                                                value={filtroVeicoli.marca}
                                                onChange={(ev) => {
                                                    updateFiltroVeicoli("marca", ev.target.value);
                                                }}/>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <input
                                                type={"text"}
                                                id={"tipo"}
                                                placeholder={"Tipo"}
                                                className="form-control"
                                                value={filtroVeicoli.tipo}
                                                onChange={(ev) => {
                                                    updateFiltroVeicoli("tipo", ev.target.value);
                                                }}/>
                                        </div>
                                        <div className={'col-md-2'}>
                                            <select
                                                id={"tipiRegistro"}
                                                placeholder={"Tipo adesione"}
                                                className="form-control form-select"
                                                value={filtroVeicoli.registro}
                                                onChange={(ev) => {
                                                    updateFiltroVeicoli("registro", ev.target.value);
                                                    setRegistro(ev.target.value);
                                                }}
                                            >
                                                <option value={""}>Tutti i registri</option>
                                                {
                                                    tipiRegistro && tipiRegistro.map((reg) => {
                                                        return <option value={reg.numero_primo}
                                                                       key={reg.numero_primo}>{reg.descrizione}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <button type="submit" className={"btn btn-dark"}>
                                                <i className="fas fa-search"></i> <Translate>Cerca</Translate>
                                            </button>
                                            <button type="button" className={"btn btn-dark"} onClick={() => {
                                                initFiltroVeicoli();
                                                setPagina(1);
                                            }}>
                                                <i className="fas fa-trash"></i> <Translate>Azzera filtri</Translate>
                                            </button>
                                            <button className={"btn btn-card"}
                                                    type={"button"}
                                                    onClick={getDownloadCsv}
                                                    data-toggle="tooltip" title="Download CSV">
                                                <i className="fas fa-download"/> <span
                                                className={'cmms-display-mobile'}>Download CSV</span>
                                            </button>
                                        </div>
                                    </div>
                                    <NsAccordion
                                        titolo={'Filtri'}
                                        label={'fas fa-filter'}
                                        defaultKey={""}
                                    >
                                        <p><strong>Dati anagrafici del proprietario</strong></p>
                                        <div className={'row'}>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"codiceFiscale"}>Codice fiscale:</label>
                                                <input
                                                    type={"text"}
                                                    id={"codiceFiscale"}
                                                    placeholder={"Codice fiscale"}
                                                    className="form-control"
                                                    value={filtroVeicoli.codiceFiscale}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("codiceFiscale", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"azienda"}>Azienda:</label>
                                                <input
                                                    type={"text"}
                                                    id={"azienda"}
                                                    placeholder={"Azienda"}
                                                    className="form-control"
                                                    value={filtroVeicoli.azienda}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("azienda", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-8'}>
                                                <label htmlFor={"note"}>Note persona:</label>
                                                <input
                                                    type={"text"}
                                                    id={"note"}
                                                    placeholder={"Note persona"}
                                                    className="form-control"
                                                    value={filtroVeicoli.notePersona}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("notePersona", ev.target.value);
                                                    }}/>
                                            </div>
                                        </div>
                                        <div className={'row pt-2'}>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"indirizzo"}>Indirizzo:</label>
                                                <input
                                                    type={"text"}
                                                    id={"indirizzo"}
                                                    placeholder={"Indirizzo"}
                                                    className="form-control"
                                                    value={filtroVeicoli.indirizzo}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("indirizzo", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"cap"}>CAP:</label>
                                                <input
                                                    type={"text"}
                                                    id={"cap"}
                                                    placeholder={"CAP"}
                                                    className="form-control"
                                                    value={filtroVeicoli.cap}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("cap", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"localita"}>Località:</label>
                                                <input
                                                    type={"text"}
                                                    id={"localita"}
                                                    placeholder={"Località"}
                                                    className="form-control"
                                                    value={filtroVeicoli.localita}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("localita", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"provincia"}>Provincia:</label>
                                                <input
                                                    type={"text"}
                                                    id={"provincia"}
                                                    placeholder={"Provincia"}
                                                    className="form-control"
                                                    value={filtroVeicoli.provincia}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("provincia", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"regione"}>Regione:</label>
                                                <select id={"regione"}
                                                        placeholder={"Regione"}
                                                        className="form-control form-select"
                                                        value={filtroVeicoli.regione}
                                                        onChange={(ev) => {
                                                            updateFiltroVeicoli("regione", ev.target.value);
                                                            setRegione(ev.target.value);
                                                        }}
                                                >
                                                    <option value={""}>Tutte le regioni</option>
                                                    {
                                                        regioni && regioni.map((reg) => <option value={reg}
                                                                                                key={reg}>{reg}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"nazione"}>Nazione:</label>
                                                <input
                                                    type={"text"}
                                                    id={"nazione"}
                                                    placeholder={"Nazione"}
                                                    className="form-control"
                                                    value={filtroVeicoli.nazione}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("nazione", ev.target.value);
                                                    }}/>
                                            </div>
                                        </div>
                                        <p className={"pt-2"}><strong>Dati sull'iscrizione del proprietario</strong></p>
                                        <div className={"row"}>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"tipoSocio"}>Tipo Socio:</label>
                                                <select id={"tipoSocio"}
                                                        placeholder={"Tipo Socio"}
                                                        className="form-control form-select"
                                                        value={filtroVeicoli.tipoSocio}
                                                        onChange={(ev) => {
                                                            updateFiltroVeicoli("tipoSocio", ev.target.value);
                                                            setTipoSocio(ev.target.value);
                                                        }}
                                                >
                                                    <option value={""}>Tutti tipi socio</option>
                                                    {
                                                        tipiSoci && tipiSoci.map((soc) => <option
                                                            value={soc.id_tipo_soci}
                                                            key={soc.id_tipo_soci}>{soc.descrizione}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"annoIscrizione"}>Anno iscrizione:</label>
                                                <input
                                                    type={"text"}
                                                    id={"annoIscrizione"}
                                                    placeholder={"Anno iscrizione"}
                                                    className="form-control"
                                                    value={filtroVeicoli.annoIscrizione}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("annoIscrizione", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"tessera"}>Numero tessera:</label>
                                                <input
                                                    type={"text"}
                                                    id={"tessera"}
                                                    placeholder={"N° tessera RFI/Abarth"}
                                                    className="form-control"
                                                    value={filtroVeicoli.filtroTessera}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("filtroTessera", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"scadenza"}>Scadenza:</label>
                                                <input
                                                    type={"text"}
                                                    id={"scadenza"}
                                                    placeholder={"Scadenza"}
                                                    className="form-control"
                                                    value={filtroVeicoli.scadenza}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("scadenza", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"daRatificare"}>Da Ratificare:</label>
                                                <select id={"daRatificare"}
                                                        placeholder={"Da Ratificare"}
                                                        className="form-control form-select"
                                                        value={filtroVeicoli.daRatificare}
                                                        onChange={(ev) => {
                                                            updateFiltroVeicoli("daRatificare", ev.target.value);
                                                            setDaRatificare(ev.target.value);
                                                        }}
                                                >
                                                    <option value={""}>Tutti</option>
                                                    <option value={"1"}>Da ratificare</option>
                                                    <option value={"0"}>Già ratificato</option>
                                                </select>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"newsletter"}>Newsletter:</label>
                                                <select id={"newsletter"}
                                                        placeholder={"Newsletter"}
                                                        className="form-control form-select"
                                                        value={filtroVeicoli.newsletter}
                                                        onChange={(ev) => {
                                                            updateFiltroVeicoli("newsletter", ev.target.value);
                                                            setNewsletter(ev.target.value);
                                                        }}
                                                >
                                                    <option value={""}>Tutti</option>
                                                    <option value={"1"}>Abbonati</option>
                                                    <option value={"0"}>Non abbonati</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={"row pt-2"}>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"delegazione"}>Delegazione:</label>
                                                <input
                                                    type={"text"}
                                                    id={"delegazione"}
                                                    placeholder={"Delegazione"}
                                                    className="form-control"
                                                    value={filtroVeicoli.delegazione}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("delegazione", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"tesseraAsi"}>Numero tessera ASI:</label>
                                                <input
                                                    type={"text"}
                                                    id={"tesseraAsi"}
                                                    placeholder={"N° tessera ASI"}
                                                    className="form-control"
                                                    value={filtroVeicoli.tesseraAsi}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("tesseraAsi", ev.target.value);
                                                    }}/>
                                            </div>
                                        </div>
                                        <p className={"pt-2"}><strong>Dati Veicolo</strong></p>
                                        <div className={"row"}>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"anno"}>Anno veicolo:</label>
                                                <input
                                                    type={"number"}
                                                    onWheel={(e) => e.target.blur()} // Prevent default scrolling behavior
                                                    id={"anno"}
                                                    placeholder={"Anno"}
                                                    className="form-control"
                                                    value={filtroVeicoli.anno}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("anno", ev.target.value);
                                                    }}/>
                                            </div>
                                            {
                                                !isRuoloClub() && !isRuoloAgenzia() &&
                                                <div className={'col-md-2'}>
                                                    <label htmlFor={"club"}>Club:</label>
                                                    <select id={"club"}
                                                            placeholder={"Clubs"}
                                                            className="form-control form-select"
                                                            value={filtroVeicoli.club}
                                                            onChange={(ev) => {
                                                                updateFiltroVeicoli("club", ev.target.value);
                                                                setClub(ev.target.value);
                                                            }}
                                                    >
                                                        <option value={""}>Tutti i club</option>
                                                        {
                                                            clubs && clubs.map((club) => <option value={club.id_club}
                                                                                                 key={club.id_club}>{club.descrizione !== '' ? club.descrizione : club.id_club}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            }
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"targa"}>Targa:</label>
                                                <input
                                                    type={"text"}
                                                    id={"targa"}
                                                    placeholder={"Targa"}
                                                    className="form-control"
                                                    value={filtroVeicoli.targa}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("targa", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"carrozzeria"}>Tipo carrozzeria:</label>
                                                <select id={"carrozzeria"}
                                                        placeholder={"Carrozzeria"}
                                                        className="form-control form-select"
                                                        value={filtroVeicoli.carrozzeria}
                                                        onChange={(ev) => {
                                                            updateFiltroVeicoli("carrozzeria", ev.target.value);
                                                            setTipoCarrozzeria(ev.target.value);
                                                        }}
                                                >
                                                    <option value={""}>Tutti i tipi</option>
                                                    {
                                                        carrozzerie && carrozzerie.map((carrozzeria) => <option
                                                            value={carrozzeria.id_tipo_carrozzeria}
                                                            key={carrozzeria.id_tipo_carrozzeria}>{carrozzeria.descrizione !== '' ? carrozzeria.descrizione : carrozzeria.id_tipo_carrozzeria}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"marca_carrozzeria"}>Marca carrozzeria:</label>
                                                <input
                                                    type={"text"}
                                                    id={"marca_carrozzeria"}
                                                    placeholder={"Marca Carrozzeria"}
                                                    className="form-control"
                                                    value={filtroVeicoli.marcaCarrozzeria}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("marcaCarrozzeria", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"colore"}>Colore:</label>
                                                <select id={"colore"}
                                                        placeholder={"Colore"}
                                                        className="form-control form-select"
                                                        value={filtroVeicoli.colore}
                                                        onChange={(ev) => {
                                                            updateFiltroVeicoli("colore", ev.target.value);
                                                            setColore(ev.target.value);
                                                        }}
                                                >
                                                    <option value={""}>Tutti i colori</option>
                                                    {
                                                        colori && colori.map((colore) => <option
                                                            value={colore.id_colore}
                                                            key={colore.id_colore}>{colore.descrizione !== '' ? colore.descrizione : colore.id_colore}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className={"row pt-2"}>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"tipo_motore"}>Tipo motore:</label>
                                                <input
                                                    type={"text"}
                                                    id={"tipo_motore"}
                                                    placeholder={"Tipo motore"}
                                                    className="form-control"
                                                    value={filtroVeicoli.tipoMotore}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("tipoMotore", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"numero_motore"}>Numero motore:</label>
                                                <input
                                                    type={"text"}
                                                    id={"numero_motore"}
                                                    placeholder={"Numero motore"}
                                                    className="form-control"
                                                    value={filtroVeicoli.numeroMotore}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("numeroMotore", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"numero_telaio"}>Numero telaio:</label>
                                                <input
                                                    type={"text"}
                                                    id={"numero_telaio"}
                                                    placeholder={"Numero telaio"}
                                                    className="form-control"
                                                    value={filtroVeicoli.numeroTelaio}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("numeroTelaio", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"registroVeicolo"}>Registro veicoli:</label>
                                                <select id={"registroVeicolo"}
                                                        placeholder={"Registro Veicoli"}
                                                        className="form-control form-select"
                                                        value={filtroVeicoli.registroVeicolo}
                                                        onChange={(ev) => {
                                                            updateFiltroVeicoli("registroVeicolo", ev.target.value);
                                                            setRegistroVeicoli(ev.target.value);
                                                        }}
                                                >
                                                    <option value={""}>Tutti i registri</option>
                                                    {
                                                        registriVeicoli && registriVeicoli.map((reg) => <option
                                                            value={reg.id_registro}
                                                            key={reg.id_registro}>{reg.descrizione !== '' ? reg.descrizione : reg.id_registro}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"numero_registrazione"}>Numero registrazione:</label>
                                                <input
                                                    type={"text"}
                                                    id={"numero_registrazione"}
                                                    placeholder={"Numero registrazione"}
                                                    className="form-control"
                                                    value={filtroVeicoli.numeroRegistrazione}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("numeroRegistrazione", ev.target.value);
                                                    }}/>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"data_registrazione"}>Data registrazione:</label>
                                                <NsDatePicker
                                                    nome={"data_registrazione"}
                                                    dataSelezionata={filtroVeicoli.data_registrazione}
                                                    maxDate={new Date()}
                                                    setSelectedDate={(date, event) => {
                                                        updateFiltroVeicoli("data_registrazione", date);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={"row pt-2"}>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"bollo_rfi"}>Bollo RFI:</label>
                                                <select id={"bollo_rfi"}
                                                        placeholder={"Bollo RFI"}
                                                        className="form-control form-select"
                                                        value={filtroVeicoli.bollo}
                                                        onChange={(ev) => {
                                                            updateFiltroVeicoli("bollo", ev.target.value);
                                                            setBollo(ev.target.value);
                                                        }}
                                                >
                                                    <option value={""}>Tutti</option>
                                                    <option value={"1"}>Con bollo RFI</option>
                                                    <option value={"0"}>Senza bollo RFI</option>
                                                </select>
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"omologato"}>Omologato:</label>
                                                <select id={"omologato"}
                                                        placeholder={"Omologato"}
                                                        className="form-control form-select"
                                                        value={filtroVeicoli.omologato}
                                                        onChange={(ev) => {
                                                            updateFiltroVeicoli("omologato", ev.target.value);
                                                            setOmologato(ev.target.value);
                                                        }}
                                                >
                                                    <option value={""}>Tutti</option>
                                                    <option value={"1"}>Omologati</option>
                                                    <option value={"0"}>Non omologati</option>
                                                </select>
                                            </div>
                                            <div className={'col-md-8'}>
                                                <label htmlFor={"note_veicolo"}>Note veicolo:</label>
                                                <input
                                                    type={"text"}
                                                    id={"note_veicolo"}
                                                    placeholder={"Note veicolo"}
                                                    className="form-control"
                                                    value={filtroVeicoli.noteVeicolo}
                                                    onChange={(ev) => {
                                                        updateFiltroVeicoli("noteVeicolo", ev.target.value);
                                                    }}/>
                                            </div>
                                        </div>
                                        <div className={"row pt-2"}>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"data_da"}>Registrazione dal:</label>
                                                <NsDatePicker
                                                    nome={"data_da"}
                                                    dataSelezionata={filtroVeicoli.data_da}
                                                    maxDate={filtroVeicoli.data_da}
                                                    setSelectedDate={(date, event) => {
                                                        updateFiltroVeicoli("data_da", date);
                                                    }}
                                                />
                                            </div>
                                            <div className={'col-md-2'}>
                                                <label htmlFor={"data_a"}>Registrazione al:</label>
                                                <NsDatePicker
                                                    nome={"data_a"}
                                                    dataSelezionata={filtroVeicoli.data_a}
                                                    minDate={filtroVeicoli.data_a}
                                                    setSelectedDate={(date, event) => {
                                                        updateFiltroVeicoli("data_a", date);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <p className={"pt-2"}><strong>Ordinamento</strong></p>
                                        <div className={"row"}>
                                            <div className={'col-md-4'}>
                                                <label htmlFor={"ordine"}>Ordina per:</label>
                                                <select id={"ordine"}
                                                        className="form-control form-select"
                                                        value={filtroVeicoli.ordine}
                                                        onChange={(ev) => {
                                                            updateFiltroVeicoli("ordine", ev.target.value);
                                                            setClub(ev.target.value);
                                                        }}
                                                >
                                                    <option value={""}></option>
                                                    <option value={ORDINE_VEICOLI_RFI_ASC}>Numero RFI</option>
                                                    <option value={ORDINE_VEICOLI_ABARTH_ASC}>Numero Abarth</option>
                                                    <option value={ORDINE_VEICOLI_TIPO_SOCIO}>Tipo Socio</option>
                                                    <option value={ORDINE_VEICOLI_COGNOME_ASC}>Cognome</option>
                                                    <option value={ORDINE_VEICOLI_MARCA_ASC}>Marca Veicolo</option>
                                                </select>
                                            </div>
                                        </div>
                                    </NsAccordion>
                                </Form>
                            </Formik>
                        </Fragment>
                        : ''
                }
                {
                    loadingData ? (
                        <p>Caricamento in corso...</p>
                    ) : <Fragment>
                        {
                            isRuoloSocio() &&
                            <div className="alert alert-info alert-dismissible fade show" role="alert">
                                <p>Inserisci qui i tuoi veicoli. Potrai successivamente richiedere tutti i documenti
                                    (iscrizione a Lista Chiusa; iscrizione all'Albo; richiesta Certificato di Rilevanza
                                    Storica; richiesta di Omologazione).</p>
                            </div>
                        }
                        <TabellaVeicoli
                            veicoli={veicoli}
                            totVeicoli={totVeicoli}
                            deleteVeicolo={deleteVeicolo}
                        />
                    </Fragment>
                }
                {
                    totPagine ?
                        (
                            <Pager
                                pagina={pagina}
                                totale={totPagine}
                                handlerCambioPagina={(nuovaPagina) => {
                                    setPagina(nuovaPagina)
                                }}
                            >
                            </Pager>
                        ) : ''
                }
            </Card>
        </CardContainer>
    );
}

export default ListVeicoli;
